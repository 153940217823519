@import "variable.scss";

@font-face {
  font-family: "Poppins-Regular";
  src: local("Poppins-Regular"),
    url("../assets/font/Poppins-Regular.ttf") format("truetype");
  font-weight: 300;
}
@font-face {
  font-family: "Poppins-SemiBold";
  src: local("Poppins-SemiBold"),
    url("../assets/font/Poppins-SemiBold.ttf") format("truetype");
  font-weight: 300;
}
@font-face {
  font-family: "Poppins-Medium";
  src: local("Poppins-Medium"),
    url("../assets/font/Poppins-Medium.ttf") format("truetype");
  font-weight: bold;
}
@font-face {
  font-family: "Poppins-Italic";
  src: local("Poppins-Italic"),
    url("../assets/font/Poppins-Italic.ttf") format("truetype");
  font-weight: 500;
}
@font-face {
  font-family: "Poppins-Light";
  src: local("Poppins-Light"),
    url("../assets/font/Poppins-Light.ttf") format("truetype");
  font-weight: 600;
}
@font-face {
  font-family: "Poppins-Bold";
  src: local("Poppins-Bold"),
    url("../assets/font/Poppins-Bold.ttf") format("truetype");
  font-weight: 400;
}
@media only screen and (min-height: 500px) {
  .sidebar-footer {
    position: absolute;
    bottom: 15px;
    width: 100%;
  }
}
body {
  margin: 0px !important;
  box-sizing: border-box !important;
}
body,
div,
p,
span,
button,
th,
td,
label,
input,
select,
textarea {
  font-family: Poppins-Regular !important;
}
.d-flex-a {
  display: flex;
  align-items: center;
}
.d-flex-ja {
  display: flex;
  align-items: center;
  justify-content: center;
}
.flex-column {
  flex-direction: column !important;
}
.flex-sb {
  justify-content: space-between;
}
.flex-end {
  justify-content: flex-end;
}
.commontitle {
  color: $themeblue;
  font-size: 20px !important;
  font-weight: 600 !important;
}
.footer-text {
  color: #929592;
  font-size: 12px !important;
}
.mh-85 {
  min-height: calc(100% - 115px);
}
.footer-box {
  padding: 10px 40px;
  background-color: #ffffff;
  // position: sticky;
  // bottom: 0px;
  // z-index: 10;
  .fbar {
    width: 1px;
    height: 20px;
    margin: 0 16px !important;
    background: #929592;
  }
}
.modal-title {
  color: $themeblue !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  line-height: 20px !important;
}
.modal-normal-title {
  color: #111 !important;
  font-size: 24px !important;
  font-weight: 600 !important;
  line-height: 22px !important;
  margin-bottom: 40px !important;
}
.modal-dis {
  color: #111 !important;
  font-size: 14px !important;
  font-weight: 400;
  line-height: 20px !important;
  margin: 12px 0 20px 0 !important;
}
.delete-dis {
  color: #637083 !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  line-height: 24px !important;
  margin: 0px 0 26px 0 !important;
  text-align: center;
}
.commonsubtitle {
  color: $themeblue;
  font-size: 18px !important;
  font-weight: 600 !important;
}
.MuiListItemIcon-root {
  margin-right: 0px !important;
}
.first-border {
  tbody {
    tr {
      td:not(:first-child) {
        border-right: 1px solid rgb(228, 231, 236);
      }
    }
  }
}
.second-border {
  tbody {
    tr {
      td:nth-child(even) {
        border-right: 1px solid rgb(228, 231, 236);
      }
    }
  }
}
.look-popup {
  .MuiPaper-root {
    border-radius: 8px !important;
    background: #fff;
    box-shadow: 0px 2px 4px 0px rgba(52, 64, 81, 0.25) !important;
    min-width: 120px;
    min-height: 80px;
    padding: 12px;
  }
}
.profile-popup {
  .MuiPaper-root {
    border-radius: 8px !important;
    background: #fff;
    box-shadow: 0px 2px 4px 0px rgba(52, 64, 81, 0.25) !important;
    min-width: 224px;
    min-height: 220px;
  }
  .pop-head {
    padding: 22px 24px;
    border-bottom: 1px solid #d1d1d1;
  }
  .pop-item-box {
    padding: 15px 0px;
    color: #111 !important;
    .pop-item {
      cursor: pointer;
      transition: 0.5s;
      padding: 10px 24px;
      &:hover {
        background-color: #d1d1d1;
      }
      .pop-text {
        color: #111 !important;
        font-size: 14px !important;
        font-weight: 500 !important;
        line-height: 16px;
        padding-left: 16px;
      }
    }
    .pop-item-red {
      .pop-text {
        color: #f00 !important;
      }
    }
  }
}
.sideBarActive {
  background-color: $themeblue !important;
  box-shadow: 0px 3.5px 5.5px 0px rgba(0, 0, 0, 0.02);
  border-radius: 4px;
}
.sideBarActive:hover {
  background-color: $themeblue !important;
  box-shadow: 0px 3.5px 5.5px 0px rgba(0, 0, 0, 0.02);
  border-radius: 4px;
}
.sidebarLink {
  text-decoration: none !important;
  color: unset !important;
}
.linkHide {
  text-decoration: none !important;
  color: unset !important;
}
.bottom-border {
  border-bottom: 1px solid #d1d1d1;
}
.c-note {
  font-size: 12px !important;
  font-weight: 400 !important;
  padding-left: 9px;
  color: #637083 !important;
}
.tick-icon {
  fill: rgb(20, 201, 4) !important;
  font-size: 16px !important;
}
//padding and margin
.pb-12 {
  padding-bottom: 12px;
}
.mr-24 {
  margin-right: 24px !important;
}
.mr-10 {
  margin-right: 10px !important;
}
.mt-30 {
  margin-top: 30px !important;
}
.mb-24 {
  margin-bottom: 24px !important;
}
.mt-24 {
  margin-top: 24px !important;
}
.mt-12 {
  margin-top: 12px !important;
}
.mt-40 {
  margin-top: 40px !important;
}
.mb-4 {
  margin-bottom: 4px !important;
}
.pb-24 {
  padding-bottom: 24px;
}
.pl-4 {
  padding-left: 4px;
}
.MuiAppBar-root {
  box-shadow: 0px 2px 1px 0px rgba(0, 0, 0, 0.1) !important;
}
//buttons
.cancel-btn {
  border-radius: 20px !important;
  background-color: #ffffff !important;
  color: #272727 !important;
  text-transform: capitalize !important;
  border: 1px solid lightgray !important;
  min-width: 90px !important;
  padding: 6.5px 16px !important;
  font-size: 14px !important;
  font-weight: 600 !important;
}
.btn-2r {
  margin-right: 16px !important;
}
.cancel-btn-hover {
  border-radius: 20px !important;
  background-color: #ffffff !important;
  color: gray !important;
  &:hover {
    color: #272727 !important;
    svg {
      fill: red !important;
    }
  }
  text-transform: capitalize !important;
  border: 1px solid lightgray !important;
  min-width: 90px !important;
  padding: 6.5px 16px !important;
  font-size: 14px !important;
  font-weight: 600 !important;
}
.save-btn {
  border-radius: 20px !important;
  background-color: $themeblue !important;
  color: #fff !important;
  text-transform: capitalize !important;
  min-width: 50px !important;
  padding: 7.5px 16px !important;
  font-size: 14px !important;
  font-weight: 600 !important;
}
.form-box {
  background-color: #ffffff;
  border-radius: 10px;
  padding: 40px;
  margin-top: 27px !important;
  min-height: 60vh;
}
.MuiMenuItem-root {
  font-size: 14px !important;
}
.custom-form {
  input,
  .MuiSelect-select {
    font-size: 14px !important;
    font-weight: 600 !important;
  }
  .MuiSelect-select {
    padding-top: 10.5px !important;
    padding-bottom: 10.5px !important;
  }
  .auto-complete-input {
    width: 100% !important;
    padding: 10px !important;
    font-size: 14px !important;
    font-weight: 600 !important;
    border: 1px solid #c4c4c4 !important;
    border-radius: 5px !important;
  }
  .auto-complete-input::placeholder {
    color: #b3afaf !important;
    font-size: 12px !important;
    font-weight: normal !important;
  }
  .custom-input {
    width: 100%;
    .MuiOutlinedInput-notchedOutline {
      legend {
        font-size: 0.65em !important;
      }
    }
    .MuiOutlinedInput-input {
      padding-top: 12px;
      padding-bottom: 12px;
    }
    .MuiFormLabel-root {
      font-size: 14px !important;
      font-weight: 600 !important;
      color: #3f413f !important;
    }
    .MuiFormControlLabel-label {
      font-weight: 500 !important;
      color: #111 !important;
      font-size: 14px !important;
    }
    .MuiRadio-root {
      padding: 0px 9px !important;
      svg {
        width: 16px !important;
      }
    }
    .MuiInputLabel-root {
      transform: translate(14px, 15px) scale(1);
      color: #ababab !important;
      font-weight: 500 !important;
      font-size: 12px !important;
    }
    .MuiInputLabel-shrink {
      transform: translate(14px, -9px) scale(1) !important;
      background-color: #ffffff;
      color: #333 !important;
      font-weight: 500 !important;
    }
  }
  .MuiInputBase-inputMultiline {
    font-size: 14px !important;
    font-weight: 600 !important;
    padding: 0px !important;
  }
}
table {
  thead {
    th:first-child {
      padding-left: 24px !important;
    }
  }
  tbody {
    tr {
      td:first-child {
        padding-left: 24px !important;
      }
    }
  }
}
.custom-table {
  .w-10 {
    width: 10%;
  }
  .w-15 {
    width: 15%;
  }
  .w-30 {
    width: 30%;
  }
  .w-35 {
    width: 35%;
  }
  .w-20 {
    width: 20%;
  }
  .w-25 {
    width: 25%;
  }
  .w-40 {
    width: 40%;
  }
  .MuiCheckbox-root {
    padding: unset !important;
  }
  .arrow-box {
    width: 11px;
    position: relative;
  }
  .active-row {
    background-color: #f3fffe;
  }
  table {
    tbody {
      tr:hover {
        background-color: #f3fffe;
      }
    }
  }
  .MuiTableCell-head {
    padding: 10.75px;
    background-color: #e4e7ec;
    color: #111 !important;
    font-weight: 600 !important;
    font-size: 14px !important;
    svg {
      color: #bdbdbd;
    }
    .MuiCheckbox-root {
      width: 16px;
      height: 16px;
      background: #fff;
    }
    .Mui-checked {
      svg {
        color: $themeblue;
      }
    }
  }
  .MuiTableCell-body {
    font-size: 14px !important;
    color: #111 !important;
    padding: 14.5px 10.75px;
    word-break: break-word;
    img {
      cursor: pointer;
    }
    svg {
      color: #bdbdbd;
    }
    .MuiCheckbox-root {
      width: 16px;
      height: 16px;
      background: #fff;
    }
    .Mui-checked {
      svg {
        color: $themeblue;
      }
    }
  }
  .userRow {
    .MuiTableCell-body {
      padding: 5px !important;
    }
  }
  .th-title {
    color: #111 !important;
    font-weight: 600 !important;
    font-size: 14px !important;
    text-transform: capitalize !important;
  }
  .th-title-white {
    color: #fff !important;
    font-weight: 600 !important;
    font-size: 14px !important;
  }
  .u-arrow {
    fill: rgba(146, 146, 146, 0.6);
    position: absolute;
    bottom: -6px;
    cursor: pointer;
    width: 5px;
    height: 4px;
    transform: rotate(180deg);
  }
  .d-arrow {
    fill: rgba(146, 146, 146, 0.6);
    position: absolute;
    top: -4px;
    cursor: pointer;
    width: 5px;
    height: 4px;
  }
}
.page-left-text {
  color: #272727 !important;
  font-size: 14px !important;
}
.pagination {
  align-items: center;
  li:first-child {
    button {
      height: 33.385px;
      border-radius: 7.419px;
      background: #f8f9fa;
      margin-right: 14px;
      border: none;
      padding-right: 10px;
      &::before {
        content: "<";
        top: 2px;
        position: relative;
        margin: 0 5px;
        font-size: 14px;
      }
    }
  }
  li:last-child {
    button {
      height: 33.385px;
      border-radius: 7.419px;
      background: #f8f9fa;
      border: none;
      padding-left: 10px;
      &::after {
        content: ">";
        top: 2px;
        position: relative;
        margin: 0 5px;
        font-size: 14px;
      }
    }
  }
}
.addfacilitymodal,
.deletemodal {
  .MuiBox-root {
    width: 90vw;
    overflow-y: auto;
  }
  .close-btn {
    position: absolute;
    right: 0%;
    top: 0px;
    background-color: #fff;
    &:hover {
      background-color: #fff;
    }
    svg {
      fill: $themeblue !important;
    }
  }
}
.deletemodal {
  .MuiBackdrop-root {
    background-color: rgba(0, 0, 0, 0.25) !important;
  }
}

.reportmodal {
  .modal-normal-title {
    min-width: 300px;
  }
  .close-btn {
    position: absolute;
    right: 10px;
    top: 10px;
    background-color: #fff;
    &:hover {
      background-color: #fff;
    }
    svg {
      fill: $themeblue !important;
    }
  }
}

.dashboard-title {
  font-size: 26px !important;
  font-weight: 600 !important;
  color: #111 !important;
}
.dashboard-date {
  color: #637083 !important;
  font-size: 14px !important;
  margin-top: 12px;
}
.result-sticky-head {
  thead {
    tr:first-child {
      th:first-child {
        z-index: 1001;
      }
      th {
        top: 0px;
        z-index: 1000;
      }
    }
    tr:last-child {
      th:first-child {
        z-index: 1001;
      }

      th {
        top: 45px !important;
        z-index: 1000;
      }
    }
  }
}
.dashboard-batch {
  table {
    tbody {
      tr:nth-child(even) {
        background-color: #f9fafb;
      }
      tr:hover {
        background-color: #f3fffe;
      }
    }
  }
  .blue-header {
    background-color: $themeblue !important;
    color: #fff !important;
  }
  .ligt-green {
    background-color: rgb(91, 108, 91) !important;
    color: #fff !important;
  }
  .small-green {
    // background-color: rgb(100, 161, 100) !important;
    // color: #fff !important;
    color: #000 !important;
    background-color: #c4d79b !important;
  }
  .grey-header {
    background-color: rgb(78, 77, 80) !important;
    color: #fff !important;
  }
  .third-header {
    background-color: #ececec !important;
  }
  .green-header {
    background-color: #a7b9ae !important;
    border: 1px solid #240f0f;
  }
  .orange-cell {
    background-color: #e6662f !important;
    color: #fff !important;
    border: 1px solid #240f0f;
  }
  .yellow-cell {
    background-color: #f1c232 !important;
    color: #ffffff !important;
    border: 1px solid #240f0f;
  }
  .red-cell {
    background-color: #c0504d !important;
    color: #ffffff !important;
    border: 1px solid #240f0f;
  }
  .green-cell {
    background-color: #b6d7a8 !important;
    color: #ffffff !important;
    border: 1px solid #240f0f;
  }
  .w-10 {
    width: 10%;
  }
  .w-15 {
    width: 15%;
  }
  .w-30 {
    width: 30%;
  }
  .w-35 {
    width: 35%;
  }
  .w-55 {
    width: 55%;
  }
  .w-70 {
    width: 70%;
  }
  .w-20 {
    width: 20%;
  }
  .w-25 {
    width: 25%;
  }
  .w-40 {
    width: 40%;
  }
  .w-100px {
    width: 100px;
  }
  .w-200px {
    width: 200px;
  }
  .MuiCheckbox-root {
    padding: unset !important;
  }
  .arrow-box {
    width: 11px;
    position: relative;
  }
  .MuiTableCell-head {
    padding: 2.5px 10.75px !important;
    color: #111;
    font-weight: 600 !important;
    background-color: #dbe1ea;
    font-size: 12px !important;
    svg {
      color: #bdbdbd;
    }
    .MuiCheckbox-root {
      width: 16px;
      height: 16px;
      background: #fff;
    }
    .Mui-checked {
      svg {
        color: $themeblue;
      }
    }
  }
  .MuiTableCell-body {
    font-size: 12px !important;
    color: #111;
    padding: 7.5px 10.75px;
    word-break: break-word;
    div,
    p {
      font-size: 12px !important;
      color: #111;
      word-break: break-word;
    }
    img {
      cursor: pointer;
    }
    svg {
      color: #bdbdbd;
    }
    .MuiCheckbox-root {
      width: 16px;
      height: 16px;
      background: #fff;
    }
    .Mui-checked {
      svg {
        color: $themeblue;
      }
    }
  }
  .th-title {
    color: #111 !important;
    font-weight: 600 !important;
    font-size: 12px !important;
    text-transform: capitalize !important;
  }
  .u-arrow {
    fill: rgba(146, 146, 146, 0.6);
    position: absolute;
    bottom: -6px;
    cursor: pointer;
    width: 5px;
    height: 4px;
    transform: rotate(180deg);
  }
  .d-arrow {
    fill: rgba(146, 146, 146, 0.6);
    position: absolute;
    top: -4px;
    cursor: pointer;
    width: 5px;
    height: 4px;
  }
}
.dashboad-tab {
  .download {
    color: #637083 !important;
    font-size: 14px !important;
    font-weight: 600 !important;
    margin-right: 14px;
  }
  .MuiTabs-indicator {
    background-color: $themeblue;
  }
  .im {
    width: 26px;
    height: 26px;
    cursor: pointer;
  }
  .wordPng {
    width: 30px;
    height: 30px;
    cursor: pointer;
  }
  .pdf {
    margin-left: 18px;
  }
  .MuiTabPanel-root {
    padding: 24px 0px !important;
  }
  .MuiTabs-root {
    min-height: 36px !important;
  }
  .MuiTab-textColorPrimary {
    font-size: 16px !important;
    text-transform: capitalize !important;
    font-weight: 500 !important;
    color: #637083;
    padding-top: 8px !important;
    padding-bottom: 8px;
    &.Mui-selected {
      color: #ffffff;
      background-color: $themeblue;
    }
  }
  .MuiTabs-flexContainer {
    button {
      min-height: 36px !important;
    }
    button:last-child {
      border-radius: 4px 4px 0px 0px;
      border-top: 1px solid $themeblue;
      border-right: 1px solid $themeblue;
      border-left: 1px solid $themeblue;
    }
    button:first-child {
      border-radius: 4px 4px 0px 0px;
      border-top: 1px solid $themeblue;
      border-right: 1px solid $themeblue;
      border-left: 1px solid $themeblue;
    }
    button:nth-child(2) {
      border-radius: 4px 4px 0px 0px;
      border-top: 1px solid $themeblue;
      border-right: 1px solid $themeblue;
      border-left: 1px solid $themeblue;
    }
  }
  .bottom-border {
    border-bottom: 1px solid $themeblue;
  }
}
.custom-tab {
  .MuiTab-textColorPrimary {
    font-size: 18px !important;
    text-transform: capitalize !important;
    font-weight: 600 !important;
    color: #637083;
    padding-top: 8px !important;
    &.Mui-selected {
      color: $themeblue;
    }
  }
  .MuiTabPanel-root {
    padding: 30px 8px !important;
    min-height: 300px;
  }
  .MuiTabs-flexContainer {
    .MuiButtonBase-root {
      margin-right: 12px;
    }
    button:first-child {
      padding-left: 6px !important;
      padding-right: 6px !important;
    }
  }
  .MuiTabs-indicator {
    background-color: $themeblue;
    height: 3px;
  }
}
.trans-box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80vw;
  min-height: 200px;
  max-height: 500px;
  overflow-y: scroll;
  background-color: #ffffff;
  border-radius: 6px;
  box-shadow: 0px 2px 4px 0px rgba(147, 147, 147, 0.25);
  padding: 32px 80px 40px 40px;
  .v-title {
    color: #111 !important;
    font-size: 24px !important;
    font-weight: 600 !important;
    margin-bottom: 24px;
  }
  .p-rel {
    position: relative;
    margin-bottom: 24px;
    .add {
      cursor: pointer;
      position: absolute;
      right: -35px;
      top: 35px;
    }
  }
}
.custom-sub-tab {
  .MuiTab-textColorPrimary {
    font-size: 16px !important;
    text-transform: capitalize !important;
    font-weight: 600 !important;
    color: #111 !important;
    padding-top: 8px !important;
    &.Mui-selected {
      color: #111 !important;
    }
  }
  .MuiTabs-root {
    min-height: 40px !important;
  }
  .MuiTabPanel-root {
    padding: 30px 8px !important;
    min-height: 300px;
  }
  .MuiTabs-flexContainer {
    .MuiButtonBase-root {
      margin-right: unset !important;
      &.Mui-selected {
        border-radius: 6px 6px 0px 0px !important;
        background: #e4e7ec !important;
      }
      border-radius: 6px 6px 0px 0px;
      border: 1px solid #e4e7ec;
      border-bottom: unset !important;
      background: #fff;
      min-height: 40px !important;
      padding-top: 5px !important;
      padding-bottom: 5px !important;
      min-width: 128px !important;
    }
    button:first-child {
      padding-left: 6px !important;
      padding-right: 6px !important;
    }
  }
  .MuiTabs-indicator {
    background-color: unset;
    height: 3px;
  }
}
.login-bg {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  .log-footer {
    position: absolute;
    bottom: 20px;
    left: 20px;
    color: #ffffff;
    font-size: 14px !important;
    font-weight: 500 !important;
  }
}
.log-foot-icon {
  width: 80px;
}
.login-form-box {
  margin: auto !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: relative;
  min-height: 100vh;
  width: 280px;
  .log-h1 {
    font-weight: 600 !important;
    font-size: 40px;
    margin-bottom: 8px !important;
    color: #111 !important;
    line-height: 50px !important;
  }
  .log-p {
    font-size: 14px !important;
    color: #637083 !important;
    margin-bottom: 40px !important;
    text-align: center;
    font-weight: 500 !important;
    line-height: 24px !important;
  }
  .log-span {
    font-size: 12px !important;
    font-weight: 500 !important;
    padding-left: 5px;
    color: #111 !important;
  }
}
.org-title {
  font-weight: 600 !important;
  font-size: 14px !important;
  margin-bottom: 37px !important;
  color: #333 !important;
}
.org-profile {
  width: 82px;
  height: 82px;
  object-fit: contain;
  margin-bottom: 45px !important;
}
.org-btn {
  border: 1px solid $themeblue !important;
  border-radius: 4px !important;
  min-width: 100px !important;
  margin-bottom: 22px !important;
  text-transform: capitalize !important;
  color: #333333 !important;
  font-size: 12px !important;
}
.org-remove {
  color: $themeblue !important;
  cursor: pointer;
  font-size: 12px !important;
  font-weight: 500 !important;
  margin-bottom: 24px !important;
}
.org-support {
  color: #929292 !important;
  font-size: 12px !important;
  font-weight: 500 !important;
  margin-bottom: 8px !important;
}
.MuiButton-startIcon {
  margin-right: 4px !important;
  margin-left: 0px !important;
  svg {
    font-size: 18px !important;
  }
}
::-webkit-scrollbar {
  width: 0.5em;
  height: 0.5em;
  border-radius: 4px;
  cursor: pointer;
  scrollbar-width: thin;
}

::-webkit-scrollbar-track-piece {
  background: #e8e8e8;
  border-radius: 100px;
  cursor: pointer;
}

::-webkit-scrollbar-thumb {
  background: #7a7a7a;
  border-radius: 100px;
  cursor: pointer;
}

.MuiFormHelperText-root {
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.66;
  letter-spacing: 0.03333em;
  text-align: left;
  margin-top: 3px;
  margin-right: 14px;
  margin-bottom: 0;
  margin-left: 0 !important;
}

.MuiFormHelperText-root.Mui-error {
  color: #d32f2f;
}
.result-chart {
  min-height: 455px;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 4px 21px 2px rgba(0, 0, 0, 0.09);
  padding: 24px 36px;
  .re-title {
    color: $themeblue;
    font-size: 20px;
    font-weight: 600 !important;
  }
  .re-dis {
    color: #637083 !important;
    font-size: 14px !important;
    margin-top: 10px;
  }
  .chart-head {
    color: #637083;
    font-size: 18px;
    font-weight: 500 !important;
    position: absolute;
    top: 15px;
    z-index: 1;
    width: 100%;
    text-align: center;
  }
}
.th-avator {
  height: 40px;
  width: 40px;
  border-radius: 25px;
  background-color: #3966b8;
  color: #ffffff !important;
  font-family: "Red Hat Display Bold" !important;
  font-size: 18px !important;
  line-height: 23px !important;
  margin-right: 10px !important;
}
.mobile-footer-none {
  display: none;
}
.inline-flex {
  display: inline-flex;
  align-items: center;
}
.in-flex {
  display: inline-flex;
  align-items: center;
  width: 100%;
}
.mobileOut {
  width: 408px;
}
.standard-sticky {
  table {
    thead {
      tr:last-child {
        th {
          top: 43px !important;
        }
      }
    }
  }
}
.weblogo,
.w280-block {
  display: none !important;
}
.mbarclose {
  display: none !important;
}
@media (max-width: 615px) {
  .w280 {
    display: none !important;
  }
  .w280-block {
    display: block !important;
  }
}
@media (min-width: 1280px) {
  .login-form-box {
    width: 400px !important;
  }
  .sticky-table {
    position: sticky;
    top: 94px;
  }
}
@media (min-width: 1081px) {
  header {
    .MuiToolbar-root {
      padding-left: unset !important;
    }
  }
  .weblogo {
    width: 100% !important;
    height: 55px !important;
    margin-left: -33px;
    display: block !important;
    img {
      object-fit: contain !important;
    }
  }
  .webclose {
    background-color: #0b0b0b;
    width: 239px;
    height: 75px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 12px 0 24px;
  }
  .w240 {
    width: calc(100% - 240px);
  }
  .w64 {
    width: calc(100% - 65px);
  }
  .addfacilitymodal {
    .MuiBox-root {
      width: 738px !important;
    }
  }
  .deletemodal {
    .MuiBox-root {
      width: 430px !important;
    }
  }
}
@media (min-height: 600px) and (min-width: 1279px) {
  .log-foot-icon {
    position: absolute;
    right: -45px;
    bottom: 25px;
  }
}
@media (max-width: 767px) {
  .chart-head {
    font-size: 12px !important;
  }
  .fbar {
    display: none !important;
  }
  .MuiTabs-scroller {
    overflow: unset !important;
  }
  .re-title {
    font-size: 16px !important;
  }
  .result-chart {
    padding: 12px !important;
  }
  .commontitle {
    font-size: 16px !important;
    margin-bottom: 12px !important;
  }
  .custom-tab,
  .form-box {
    padding: 12px !important;
  }
  .d-center {
    text-align: center;
  }
  .d-flex-a {
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
  }
  .mobileOut {
    width: 80vw !important;
  }
  .log-h1 {
    font-size: 30px !important;
  }
  table {
    .d-flex-a {
      flex-wrap: nowrap !important;
      justify-content: unset !important;
      flex-direction: row !important;
    }
  }
  nav {
    width: 100%;
    .pagination {
      overflow: auto;
    }
  }
  .inline-flex {
    margin-top: 12px !important;
  }
  .page-left-text,
  .dashboard-date {
    margin-bottom: 10px !important;
  }
  .o2 {
    order: 2;
    width: 100%;
  }
  .o1 {
    order: 1;
    margin-bottom: 10px !important;
  }
}
@media (max-width: 840px) {
  .mobile-atom {
    width: 80px !important;
    img {
      object-fit: contain !important;
    }
  }
  .flex-m-column {
    flex-direction: column;
  }
  .o2 {
    order: 2;
    width: 100%;
  }
  .o1 {
    order: 1;
    margin-bottom: 10px !important;
  }
}
@media (max-width: 599px) {
  .mobileHeader {
    a {
      button {
        margin-right: unset !important;
      }
    }
    .save-btn {
      font-size: 7px !important;
      justify-content: space-between !important;
      margin-right: 0px !important;
      svg {
        font-size: 9px !important;
      }
    }
  }
  .MuiToolbar-root {
    justify-content: space-between;
  }
  .mbarclose {
    margin-left: 0px !important;
  }
  .MuiTabs-flexContainer {
    overflow-y: auto;
  }
}
@media (max-width: 1080px) {
  .mobileContainer {
    display: block !important;
  }
  .MuiDrawer-root {
    position: fixed;
    z-index: 10000;
  }
  .mobileClose {
    .MuiDrawer-paper {
      left: 0px;
    }
  }
  .mnone {
    display: none !important;
  }
  .mbarclose {
    display: block !important;
  }
  .mbaropen {
    display: block !important;
  }
  .mobileHeader {
    margin-left: unset !important;
    left: 0px !important;
    right: 0px;
    width: 100% !important;
  }
  .mobileOpen {
    .MuiDrawer-paper {
      left: -240px;
    }
  }
}
@media (min-width: 250px) and (max-width: 899px) {
  .laptop-footer {
    display: none;
  }
  .mobile-footer-none {
    display: block;
    color: #111 !important;
    margin: 12px 0px !important;
  }

  .login-form-box {
    width: 350px !important;
  }
}
@media (min-width: 769px) and (max-width: 1080px) {
  .login-form-box {
    width: 435px !important;
  }
}
@media (min-width: 900px) and (max-width: 1080px) {
  .MuiGrid-root {
    -webkit-flex-basis: 100% !important;
    -ms-flex-preferred-size: 100% !important;
    flex-basis: 100% !important;
    flex-grow: 0 !important;
    max-width: 100% !important;
  }
}
.noRecordsText {
  font-family: Poppins-Regular !important;
  font-size: 14px !important;
  line-height: 23px !important;
  font-weight: 500 !important;
}
.blue-header {
  background-color: $themeblue !important;
  color: #fff !important;
  font-weight: bold !important;
  font-size: 14px !important;
}
.Mui-disabled {
  -webkit-text-fill-color: black !important;
}
.object-fit-contain {
  width: "100% !important";
  img {
    object-fit: contain !important;
  }
}

.pac-container {
  z-index: 1301 !important;
}

.pac-container::after {
  display: none !important;
}
